import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { faUser } from "@fortawesome/free-solid-svg-icons";


const NavBar = () => {

  const [hideLoginButton, setHideLoginButton] = useState("")
  const [toggleState, setToggleState] = useState(true)
  const [customMargins, setCustomMargins] = useState("-100px")

  return (
    <div id="hideOnPrint">
      <nav className="navbar navbar-light bg-primary mb-3 navbar-sm">
        <span className="navbar-brand">
          <Link to="/" className="text-light">
            <i className="mb-2 mx-3 fas fa-home"> Home</i>
          </Link>
        </span>


        <Dropdown className="button-focus-css" onClick={() => {
          console.log("toggleState:", customMargins)
          setToggleState(!toggleState)
        }}>
          <Dropdown.Toggle id="dropdown-basic">More Options
            {/* {" "}<FontAwesomeIcon icon={faUser} className="text-white" /> */}
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ marginLeft: "-110px" }}>

            <Dropdown.Item>
              <Link style={{ textDecoration: "none"}} to="/" className="mb-1 text-center">
                Regular Smart Reports
              </Link>
            </Dropdown.Item>

            <Dropdown.Item>
              <Link style={{ textDecoration: "none"}} to="/historical" className="mb-1 text-center">
                Historical Smart Reports
              </Link>
            </Dropdown.Item>

          </Dropdown.Menu>
        </Dropdown>

        {/* <span>
          <Link to="/upload" style={{ textDecoration: 'none' }} className="text-light mb-1 mx-3 text-center">
            Pdf upload
          </Link>
        </span> */}

      </nav>
    </div>
  );
};

export default NavBar;