import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//import HomePage from './components/HomePage'
import NavBar from './components/NavBar';
import DragDropCsv from './components/DragDropCsv';
import HistoricalDragDropCsv from './components/HistorialDragDropCsv';


const App = () => {
    return (
        <div>
            <Router>
                <NavBar />
                <div className="container">
                    <Switch>
                        <Route path="/" component={DragDropCsv} exact /> 
                        <Route path="/historical" component={HistoricalDragDropCsv} exact /> 
                    </Switch>
                </div>
            </Router>
        </div>
    )
}

export default App
