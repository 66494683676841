import React, { useState, useEffect } from 'react';
import { FileDrop } from 'react-file-drop';
import axios from 'axios'
import { Table } from 'react-bootstrap';
import { Alert, Form } from 'react-bootstrap'
import JsZip from 'jszip';
import { saveAs } from 'file-saver';


const DragDropCsv = () => {

    const [myFile, setMyFile] = useState([])
    const [error, setError] = useState("")
    const [chooseFile, setChooseFile] = useState(false)
    const [collectedAdvices, setCollectedAdvices] = useState([])
    const [loading, setLoading] = useState(false)
    const [pdfRecievedString, setPdfRecievedString] = useState(false)
    const [secondApiResponseStatus, setSecondApiResponseStatus] = useState(false)
    const [secondApiResponseSuccessStatus, setSecondApiResponseSuccessStatus] = useState(false)
    const [finalPdfLink, setFinalPdfLink] = useState(false)
    const [finalPdfStatus, setFinalPdfStatus] = useState(false)
    const [invalidFileErrorStatus, setInvalidFileErrorStatus] = useState(false)
    const [invalidPdfErrorStatus, setInvalidPdfErrorStatus] = useState(false)
    const [hideExtractingPdfLoader, setHideExtractingPdfLoader] = useState(false)
    const [selectionType, setSelectionType] = useState("no")
    const [sampleId, setSampleId] = useState("")
    const [userAllInfo, setAllUserInfo] = useState("")
    const [pdfLinks, setPdfLinks] = useState([])
    const [cSVBase64s, setCSVBase64s] = useState([])
    const [reportTypeAndSmartReportIdInputsStatus, setReportTypeAndSmartReportIdInputsStatus] = useState(false)
    const [gettingCsvSRstatus, setGettingCsvSRstatus] = useState(false)
    const [showZipButton, setShowZipButton] = useState(false)
    const [hideGenerateReportsButton, setHideGenerateReportsButton] = useState(false)
    const [disclaimerProvider, setDisclaimerProvider] = useState("")
    const [errorRecieved, setErrorRecieved] = useState("")


    const styles = {
        border: '3px dashed grey',
        width: "100%",
        color: 'black',
        padding: 20,
        backgroundColor: "#e6f7ff"
    };

    let requiredAdvicesArray = []

    const csvJSON = (csv) => {

        var lines = csv.split("\n");
        console.log("lines", lines)
        var result = [];
        var headers = lines[0].replace(/\s/g, "").split(",");


        for (var i = 1; i < lines.length; i++) {

            var obj = {};
            var currentline = lines[i].replace(/\r/g, "").split(",");

            for (var j = 0; j < headers.length; j++) {
                let currentHeader = headers[j].toLowerCase()
                obj[currentHeader] = currentline[j];
            }

            result.push(obj);

        }

        //let json = JSON.stringify(result)
        console.log("obj in result", result)
        return result; //JSON
    }


    const generateSRFromCSV = () => {
        try {
            //let check = myFile[0].type === "application/vnd.ms-excel"

            console.log("myFile", myFile)
            let files = myFile
            const formData = new FormData();
            formData.append('files[]', files[0])
            const reader = new FileReader();
            reader.onload = function (e) {
                var contents = e.target.result;
                //console.log("the contents", contents)
                const finalJson = csvJSON(contents)
                requiredJsonFormat(finalJson)
            }
            reader.readAsText(files[0])

        } catch (error) {
            console.log("error in csv\n", error)
        }
    }

    let myArray = []

    const requiredJsonFormat = (data) => {

        console.log("my data", data)
        setReportTypeAndSmartReportIdInputsStatus(true)
        setGettingCsvSRstatus(true)

        let requiredInfo = ""
        let profileRecieved = ""
        let patientDetails = ""
        let patientTest = ""
        let patientTestRecord = ""
        let patientRecord = ""
        var copyCurrentTestName = ""

        for (let i = 0; i < data.length; i++) {

            // if (data[i].patientname === "" && data[i].profilename === "" && data[i].test === copyCurrentTestName) {
            //     console.log("past obs\n", data[i])
            //     console.log("my array\n", myArray)

            //     // locate the profile
            //     for (let q = 0; q < myArray.length; q++) {
            //         let profilesCollection = myArray[q].results
            //         for (let t = 0; t < profilesCollection.length; t++) {
            //             let testsArray = profilesCollection[t].investigation
            //             for (let s = 0; s < testsArray.length; s++) {
            //                 if (testsArray[s].test === copyCurrentTestName) {
            //                     testsArray[s].pastObservation.push({
            //                         "name": data[i].test,
            //                         "value": data[i].result,
            //                         "observation_time": data[i].date
            //                     })
            //                 }
            //             }
            //         }
            //     }

            // }

            if (data[i].patientname === "" && data[i].profilename === "") {

                let theDateOfTest = data[i].date
                let previousProfiles = []
                let locateProfile = requiredInfo.results.filter(eachProfile => eachProfile.profilename === profileRecieved)
                let profileFounded = locateProfile[0]
                previousProfiles = profileFounded.investigation
                console.log("previousProfiles\n", previousProfiles)

                if (data[i].result === "" || data[i].result === "NA") {
                    // do nothing
                } else {

                    if (copyCurrentTestName === data[i].test) {
                        console.log("similar", copyCurrentTestName, data[i].test)
                        for (let t = 0; t < previousProfiles.length; t++) {
                            if (previousProfiles[t].test === data[i].test) {
                                previousProfiles[t].pastObservation.push({
                                    "name": data[i].test,
                                    "value": data[i].result,
                                    "observation_time": theDateOfTest
                                })
                            }
                        }
                    } else {
                        console.log("non similar", copyCurrentTestName, data[i].test)
                        previousProfiles.push({
                            "test": data[i].test,
                            "low": data[i].low,
                            "high": data[i].high,
                            "result": data[i].result,
                            "unit": data[i].unit,
                            "observation_time": theDateOfTest,
                            "pastObservation": []
                        })
                    }

                    let findProfile = requiredInfo.results.filter(each => each.profilename === profileRecieved)
                    findProfile[0].investigation = previousProfiles

                    // record test name
                    copyCurrentTestName = data[i].test

                }
            }

            else if (data[i].patientname === "" && data[i].profilename !== "") {

                copyCurrentTestName = data[i].test
                let thisTestDate = data[i].date

                profileRecieved = data[i].profilename

                patientTest = {
                    "test": data[i].test,
                    "low": data[i].low,
                    "high": data[i].high,
                    "result": data[i].result,
                    "unit": data[i].unit,
                    "observation_time": thisTestDate,
                    "pastObservation": []
                }

                if (data[i].result === "" || data[i].result === "NA") {
                    patientTestRecord = {
                        "profilename": data[i].profilename,
                        "investigation": []
                    }
                } else {
                    patientTestRecord = {
                        "profilename": data[i].profilename,
                        "investigation": [patientTest]
                    }
                }

                let previousResults = []
                previousResults = requiredInfo.results

                previousResults.push(patientTestRecord)
            }

            else if (data[i].patientname) {
                console.log("patient name", data[i].patientname)

                profileRecieved = data[i].profilename

                patientDetails = {
                    "patientname": data[i].patientname,
                    "age": data[i].age,
                    "Gender": data[i].gender,
                    "date": data[i].date,
                    "sampleid": data[i].sampleid,
                    "packagename": data[i].packagename
                }

                patientTest = {
                    "test": data[i].test,
                    "low": data[i].low,
                    "high": data[i].high,
                    "result": data[i].result,
                    "unit": data[i].unit,
                    "observation_time": data[i].date,
                    "pastObservation": []
                }

                copyCurrentTestName = data[i].test

                if (data[i].result === "" || data[i].result === "NA") {
                    patientTestRecord = {
                        "profilename": data[i].profilename,
                        "investigation": []
                    }
                } else {
                    patientTestRecord = {
                        "profilename": data[i].profilename,
                        "investigation": [patientTest]
                    }
                }

                patientRecord = {
                    "patientname": data[i].patientname,
                    "age": data[i].age,
                    "date": data[i].date,
                    "Gender": data[i].gender,
                    "sampleid": data[i].sampleid,
                    "packagename": data[i].packagename,
                    "disclaimer": disclaimerProvider,
                    "hasPastData": true,
                    results: [
                        patientTestRecord
                    ]
                }

                requiredInfo = patientRecord
                console.log("current patient record", patientRecord)
                myArray.push(patientRecord)
            }
        }

        setAllUserInfo(myArray)
        jsonHandler()
    }

    let myCurrentValue = 0
    let csvGeneratedBase64 = []

    const jsonHandler = async () => {
        let copyOfBase64 = []
        console.log("my Array data\n", myArray)

        var config2 = {
            method: 'post',
            // url: "testing",
            url: "https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/proactiveforher/historical",
            headers: {
                'Content-Type': 'application/json'
            },
            data: myArray[myCurrentValue]
            //data: dummyData
        };
        console.log("what im sending\n", myArray[myCurrentValue])

        try {
            setHideGenerateReportsButton(true)
            const axiosData = await axios(config2)
            console.log("inside axiosdata ===> \n\n", axiosData)
            console.log("base64", axiosData.data.base64Pdf)
            csvGeneratedBase64.push(axiosData.data.base64Pdf)
            copyOfBase64.push(axiosData.data.base64Pdf)
            console.log("copyOfBase64\n", copyOfBase64)
            setCSVBase64s(csvGeneratedBase64)

            if (myCurrentValue === myArray.length) {
                // stop further execution
                setGettingCsvSRstatus(false)
                setShowZipButton(true)

            } else {
                myCurrentValue = myCurrentValue + 1
                jsonHandler()
            }

        } catch (error) {
            console.log(error.message)
            setErrorRecieved(error.message)
            setShowZipButton(true)
            setGettingCsvSRstatus(false)
        }

    }

    console.log("cSVBase64s: \n", cSVBase64s)


    // const csvHandler = (csv) => {

    //     let lines = csv.split("\n");
    //     let result = [];
    //     let headers = lines[0].replace(/\s/g, "").split(",")

    //     console.log("headers", headers)

    //     for (let i = 1; i < lines.length; i++) {

    //         let obj = {};

    //         let currentline = lines[i].replace(/\r/g, "").split(",");
    //         console.log("currentline", currentline)

    //         for (let j = 0; j < headers.length; j++) {
    //             obj[headers[j]] = currentline[j];
    //         }
    //         result.push(obj);
    //     }
    //     console.log("requiredAdvicesArray", requiredAdvicesArray, requiredAdvicesArray.length)
    //     setCollectedAdvices(requiredAdvicesArray)
    //     console.log("array data", result)
    //     return result; //JSON
    // }

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file); // turns into base64
        });
    };

    let uniqueNumber = Math.round(Date.now() + Math.random())

    const fileHandler = async () => {
        try {
            console.log("try block got checked!")
            console.log("my file", myFile)
            let files = myFile
            console.log("files\n", files[0])
            console.log("file type", files[0].type)

            const fileType = files[0].type
            if (fileType !== "application/pdf") {


                let checkExcel = fileType.indexOf("excel").toString();
                let checkCsv = fileType.indexOf("csv").toString();

                if (checkExcel !== "-1" || checkCsv !== "-1") {
                    generateSRFromCSV()
                } else {
                    setError("Invalid File!")
                }

                // here
                // generate from csv


                //setError("Invalid File!")
                //setInvalidFileErrorStatus(true)
            } else {
                setLoading(true)
                const base64Data = await getBase64(files[0])
                let theData = await base64Data.split("base64,")[1]
                //console.log("the base64 we have\n", theData)
                let jsonFormat = {
                    "data": theData,
                    "pcos": selectionType,
                    "sampleID": sampleId,
                    "uniqueNumber": uniqueNumber,
                    "disclaimer": disclaimerProvider
                }

                console.log("uniqueNumber 1st", uniqueNumber)

                if (sampleId === "") {
                    setError("Sample Id cannot be empty!")
                } else {
                    setError("")
                    var config = {
                        method: 'post',
                        url: 'https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/pdfscrap',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: jsonFormat
                    };

                    console.log("What im sending\n", jsonFormat)

                    const axiosData = await axios(config)
                    console.log("inside axiosdata ===> \n\n", axiosData)
                }
            }
        } catch (error) {
            console.log("catch block got checked!")

            if (error.response && !invalidFileErrorStatus) {
                if (error.response.status === 504) {
                    setPdfRecievedString(true)
                    secondEndpointHandler()
                }
            } else {
                console.log("my file error in else case\n", error)
                setError("File Error!")
                setLoading(false)
            }
        }
        setLoading(false)
    }

    console.log("loadingstatus", loading)

    var limits = 0

    const secondEndpointHandler = () => {
        console.log(`calling second api now!, total tries ${limits} / 4`)
        setSecondApiResponseStatus(true)
        console.log("loading status", loading)
        setTimeout(() => {
            try {
                var config = {
                    method: 'post',
                    url: 'https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/pdfextractresponse',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: { "uniqueNumber": uniqueNumber }
                };

                console.log("uniqueNumber 2nd", uniqueNumber)

                const axiosData = axios(config)
                    .then((result) => {
                        console.log("my result", result)
                        if (result.data.pdf_url === "" && limits < 4) {
                            limits += 1
                            secondEndpointHandler()
                        } else {
                            if (result.data.pdf_url === "") {
                                setInvalidPdfErrorStatus(true)
                                setHideExtractingPdfLoader(true)
                            } else {
                                setFinalPdfLink(result.data.pdf_url)
                                setFinalPdfStatus(true)
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                console.log("inside axiosdata ===> \n\n", axiosData)
                setSecondApiResponseSuccessStatus(true)
            } catch (err) {
                console.log(err.message)
            }
        }, 15000)
    }


    const downloadPDF = (myPdfDoc, emp_id, emp_name) => {
        console.log("the file getting downloaded")
        const linkSource = `data:application/pdf;base64,${myPdfDoc}`;
        const downloadLink = document.createElement("a");
        const fileName = `${emp_id}-${emp_name}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const downloadZip = () => {
        console.log("your array\n", pdfLinks)

        var zip = new JsZip();
        let allFiles = zip.folder('myfiles')

        for (let i = 0; i < cSVBase64s.length; i++) {
            allFiles.file(`${userAllInfo[i].sampleid + '-' + userAllInfo[i].patientname}.pdf`, cSVBase64s[i], { base64: true });
        }

        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                // see FileSaver.js
                saveAs(content, "SmartReports.zip");
            });
    }

    const checkFileType = (uploadedFileType) => {
        console.log("uploadedFileType", uploadedFileType)
        // let checkExcel = uploadedFileType.indexOf("excel").toString();
        // let checkCsv = uploadedFileType.indexOf("csv").toString();

        // if (checkExcel !== "-1" || checkCsv !== "-1") {
        //     setReportTypeAndSmartReportIdInputsStatus(true)
        // } else {
        //     setReportTypeAndSmartReportIdInputsStatus(false)
        // }
    }

    // console.log("selection type: ", selectionType)
    // console.log("loading status: ", loading)
    // console.log("csvGeneratedBase64\n", csvGeneratedBase64)

    return (
        <div>
            {error ?
                <Alert variant="danger">
                    {error}
                </Alert>
                : ""}
            <div style={styles}>
                <FileDrop
                    onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                    onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                    onFrameDrop={(event) => console.log('onFrameDrop', event)}
                    onDragOver={(event) => console.log('onDragOver', event)}
                    onDragLeave={(event) => console.log('onDragLeave', event)}
                    onDrop={(files, event) => {
                        console.log('onDrop!', files, event)
                        setMyFile(files)
                        checkFileType(files[0].type)
                        console.log("your file type: ", files[0].type)
                        setError("")
                    }}
                >
                    {myFile.length > 0 ?
                        <span>
                            <i className="fas fa-file-alt fa-3x text-primary mb-1"></i>
                            <div style={{ fontSize: "1.1rem" }}>
                                <b>File Recieved: </b> {myFile[0].name}
                            </div>
                        </span>
                        :
                        <div>
                            <span><i className="fas fa-file-excel fa-3x text-secondary mb-3"></i></span>
                            <div style={{ fontSize: "1.1rem", fontWeight: "600" }}>{"Drag & Drop your CSV file here"}</div>
                        </div>
                    }
                </FileDrop>
            </div>

            {chooseFile ?
                <input
                    className="form-control mt-3"
                    id="fileupload"
                    type="file"
                    onChange={(e) => {
                        setMyFile(e.target.files)
                        checkFileType(e.target.files[0].type)
                        setError("")
                    }}
                />
                :
                null
            }

            <a
                className='mt-3'
                target={"_blank"}
                style={{ textDecoration: "none", float: "right" }}
                href="https://niroggyan.s3.ap-south-1.amazonaws.com/excel_for_portals/pfh+historical+smart+reports.csv" alt="csv-link">
                Get sample csv
            </a>


            <div className="d-flex my-3">
                <button className="btn btn-info btn-sm button-focus-css" onClick={() => setChooseFile(!chooseFile)}>
                    {chooseFile ? "Cancel Upload" : "Choose File"}
                </button>


                <button
                    disabled={hideGenerateReportsButton || loading || secondApiResponseStatus || myFile.length === 0}
                    className="btn btn-success btn-sm mx-3 button-focus-css" onClick={() => {
                        fileHandler()

                    }}>
                    Generate Reports
                </button>

                <button
                    hidden={errorRecieved === "" && cSVBase64s.length === 0 || errorRecieved !== "" && cSVBase64s.length !== 0 || gettingCsvSRstatus}
                    className="btn btn-success btn-sm button-focus-css" onClick={() => {
                        setErrorRecieved("")
                        fileHandler()
                    }}>
                    Try Again
                </button>

            </div>

            {errorRecieved !== "" && cSVBase64s.length === 0 ? <span style={{ color: "red" }}>*{errorRecieved}</span> : ""}

            <div className="my-3 row" hidden={reportTypeAndSmartReportIdInputsStatus}>
                {/* <Form.Group className="col">
                    <Form.Label>
                        <span style={{ fontWeight: "500" }}>Report Type</span>
                    </Form.Label>
                    <select
                        onChange={(e) => setSelectionType(e.target.value)}
                        className="form-select"
                        defaultValue={selectionType}
                    >
                        <option value="no">Full Body Report</option>
                        <option value="yes">PCOS</option>
                    </select>
                </Form.Group>

                <Form.Group className="col" controlId="srId">
                    <Form.Label>
                        <span style={{ fontWeight: "500" }}>Smart Report Id</span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        defaultValue={sampleId}
                        placeholder="id"
                        onChange={(e) => setSampleId(e.target.value)}
                    />
                </Form.Group> */}

                <Form.Group className="col">
                    <Form.Label>
                        <span style={{ fontWeight: "500" }}>Disclaimer provider</span>
                    </Form.Label>
                    <select
                        onChange={(e) => setDisclaimerProvider(e.target.value)}
                        className="form-select"
                        defaultValue={disclaimerProvider}
                    >
                        <option value="">None</option>
                        <option value="Metropolis Healthcare Limited">Metropolis Healthcare Limited</option>
                        <option value="Thyrocare Technologies Limited">Thyrocare Technologies Limited</option>
                        <option value="IGenetic Diagnostics Private Limited">IGenetic Diagnostics Private Limited</option>
                        <option value="Neuberg Diagnostics Private Limited">Neuberg Diagnostics Private Limited</option>
                    </select>
                </Form.Group>

            </div>

            <div>
                {pdfRecievedString ?
                    <span>
                        <i className="far fa-check-circle text-primary"></i> Extracted PDF Data
                    </span>
                    :

                    ""
                }
            </div>
            <div>
                {gettingCsvSRstatus ?
                    <span>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span> Fetching Smart Reports...</span>
                    </span>
                    :
                    ""
                }

            </div>
            <div>
                {!loading ?
                    ""
                    :
                    <span>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span> Analyzing Pdf</span>
                    </span>

                }
            </div>

            <div hidden={hideExtractingPdfLoader}>
                {secondApiResponseStatus && !finalPdfStatus
                    ?
                    <span>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span> Extracting PDF URL</span>
                    </span>
                    :
                    ""
                }
            </div>
            {hideExtractingPdfLoader ?
                <div>
                    <span className="text-danger" ><i class="far fa-times-circle"></i></span>
                    <span> PDF Error!</span>
                </div>
                : ""
            }
            <div>
                {secondApiResponseSuccessStatus && finalPdfStatus ?
                    <span>
                        <i className="far fa-check-circle text-primary"></i>
                        <span> PDF Generated!</span>
                    </span>
                    : ""}
            </div>
            <div className="my-3">
                {invalidPdfErrorStatus ?
                    <div>
                        <Alert variant="danger">
                            <span>There are some irregularities in the structure of the uploaded PDF. Please <a href="#" onClick={() => window.location.reload()}>try again</a> with a different PDF!</span>
                        </Alert>
                    </div>
                    :
                    ""
                }
                {finalPdfStatus && !invalidPdfErrorStatus ?
                    <a style={{ textDecoration: "none" }} href={finalPdfLink} target="_blank">View PDF <i class="fas fa-file-pdf"></i></a>
                    : ""
                }</div>


            {/* CSV Smart Reports UI Handling */}

            <div>
                {cSVBase64s.length === 0 || cSVBase64s === undefined ?
                    ""
                    :
                    <div>
                        <Table striped bordered className="text-center">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Sample Id</th>
                                    <th>Patient Name</th>
                                    <th>
                                        Download Link
                                        {showZipButton ?
                                            <span
                                                style={{ marginLeft: "8px" }}
                                                className="btn btn-outline-success btn-sm button-focus-css"
                                                onClick={downloadZip}>Zip all Reports <i class="far fa-file-archive"></i></span>
                                            : ""}
                                    </th>
                                </tr>
                            </thead>

                            {cSVBase64s.map((eachRecord, idx) => (
                                <tbody>
                                    <tr key={idx + 1}>
                                        <td>{idx + 1}</td>
                                        <td>{userAllInfo[idx].sampleid}</td>
                                        <td>{userAllInfo[idx].patientname}</td>
                                        <td>
                                            <span
                                                className="button-hover-css text-primary"
                                                onClick={() => downloadPDF(eachRecord, userAllInfo[idx].sampleid, userAllInfo[idx].patientname)}
                                                style={{ marginTop: "18px" }}
                                            >
                                                download pdf <i className="fas fa-download"></i>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </Table>

                    </div>
                }
            </div>
        </div>
    )
}

export default DragDropCsv